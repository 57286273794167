import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Card, Spin, Divider } from "antd";
import CorridorMap from "./CorridorMap";
import CorridorColorReferences from "./CorridorColorReferences";
import Corridor from "../../../../../../models/Corridor";
import SweepService from "../../../../../../services/SweepService";
import WarehouseService from "../../../../../../services/WarehouseService";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../../../../context/LanguageContext";
import { SweepShow } from "../../../../../../models/Sweep";

const { Panel } = Collapse;

interface CorridorCollapseProps {
  sweepId: string;
  warehouseIdx:string;
  sweep: SweepShow;
}

const SweepMap: React.FC<CorridorCollapseProps> = (props) => {
  const { language } = useLanguage();
  const [loading, setLoading] = useState<boolean>(true);
  const [corridors, setCorridors] = useState<Corridor[]>([]);
  const [sweepId] = useState<string>(props.sweepId);
  const navigate=useNavigate()
  const updateContent = useCallback(() => {
    // setLoading(true);
      if (props.sweep.warehouse){
        WarehouseService.getCorridors(props.warehouseIdx).then((warehouse) => {
          if (warehouse ) {
            setCorridors(warehouse);

            console.log(warehouse);
          
          setLoading(false);
          }
        });
      }
  }, [sweepId,navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 15*60000);
    return () => clearInterval(interval);
  }, [updateContent]);

  return (
    <Spin spinning={loading}>
      <CorridorColorReferences />
      <Divider orientation="center">{language==="es"?"Pasillos":"Corridors"}</Divider>
      <Collapse accordion destroyInactivePanel>
        {corridors.map((corridor) => (
          <Panel header={language==="es"?`Pasillo ${corridor.name}`:`Corridor ${corridor.name}`} key={corridor.name}>
            <Card>
              <CorridorMap
                sweepId={props.sweepId}
                corridorId={corridor.idx}
              />
            </Card>
          </Panel>
        ))}
      </Collapse>
    </Spin>
  );
};

export default SweepMap;
