import { CorridorSummary } from "./Corridor";

export class WarehouseSummary {
  id: string = "";
  idx: number = 0;
  name: string = "";
  warehouse_number: string = "";
  corridors: Number = 0;
  devices: Number = 0;
  positions: Number = 0;
  empty_positions: Number = 0;
  full_positions: Number = 0;
  unknown_positions: Number = 0;
  status: string = "";

  public static from(json: any) {
    return Object.assign(new WarehouseSummary(), json) as WarehouseSummary;
  }
}

export default class WarehouseShow {
  id: string = "";
  idx: number = 0;
  name: string = "";
  warehouse_number: string = "";
  corridors: Array<CorridorSummary> = [];

  public static from(json: any) {
    return Object.assign(new WarehouseShow(), json) as WarehouseShow;
  }
}
export class WarehouseBase {
  id: string = "";
  idx: number = 0;
  name: string = "";
  warehouse_number: string = "";

  public static from(json: any) {
    return Object.assign(new WarehouseBase(), json) as WarehouseBase;
  }
}


export class PendingCount {
  pending_count: number = 0;
  last_update: string = "";

  public static from(json: any) {
    return Object.assign(new PendingCount(), json) as PendingCount;
  }
}

export class LayoutShowSchema{
  template_name: string = "";
  level_range: [number, number] = [0, 0];
  column_range: [number, number] = [0, 0];
  ignore_cells: Array<[number, number[]]> = [];
  orientation: string = "";

  public static from(json: any) {
    return Object.assign(new LayoutShowSchema(), json) as LayoutShowSchema;
  }
}
    
