import { Button, Col, Modal, notification, Row, Spin, Table, Tag, Tooltip } from "antd";
import { Breakpoint } from "antd";
import {
  AreaChartOutlined,
  CheckCircleOutlined,
  ExclamationCircleFilled,
  InfoCircleOutlined,
  MinusCircleOutlined,
  StopOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import SweepBase from "../../../../../models/Sweep";
import SweepService from "../../../../../services/SweepService";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../../../context/LanguageContext";


interface SweepsTableProps {
  sweeps: SweepBase[];
  page: number;
  pageSize: number;
  total:number;
  onPageChange: (page: number, pageSize: number) => void;
  onSort?: (column_key: string, order: string) => void;
  onUpdateContent: () => void;
  writePermission: Boolean;
  isMobile: Boolean;
  loading: boolean;
}

const SweepsTable = (props: SweepsTableProps) => {
  const { language } = useLanguage();
  const creatingTimeoutRef = useRef<{ [key: string]: boolean }>({});
  const { confirm } = Modal;
  const showConfirm = (type: string, handler: (idx: number) => void, idx: number) => {
    if (language === "es") {
      switch (type) {
        case "finish":
          type = "finalizar"
          break
        case "cancel":
          type = "cancelar"
          break
      }

    }
    confirm({
      title: language === "es" ? `Desea ${type} este Barrido?` : `Do you want to ${type} these Sweep?`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        console.log('OK');
        handler(idx)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const [sweepStatus] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    creatingTimeoutRef.current = {};
  }, [props.sweeps]);

  const handleEnd = async (idx: number) => {
    try {
      var sweepToUpdate = props.sweeps.find(sweep => sweep.idx === idx);
      if (sweepToUpdate === undefined) {
        return;
      }
      sweepToUpdate.status = "processed"
      SweepService.updateStatus(sweepToUpdate).then(() => {
        props.onUpdateContent();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleStop = async (idx: number) => {
    try {
      var sweepToUpdate = props.sweeps.find(sweep => sweep.idx === idx);
      if (sweepToUpdate === undefined) {
        return;
      }
      sweepToUpdate.status = "stopped"
      SweepService.updateStatus(sweepToUpdate).then(() => {
        props.onUpdateContent();
      });
    } catch (error) {
      console.error(error);
    }
  }
  const handleCSV = (idx: string) => {
    SweepService.statusCSV(idx).then((status) => {
      if (status != null && status === true) {
        SweepService.exportCSV(idx)
      }
      else {
        if (status === false) {
          notification.info({
            message: language === "es" ? "El CSV no está listo" : "CSV is not ready",
            description: language === "es" ? `El CSV para el barrido N°${idx} todavía se está generando` : `CSV for sweep ${idx} is still being generated`,
          });
        }
      }
    })
  }


  const getStatusTag = (status: string, idx: number) => {
    let tagIcon;
    let tagColor;
    let showstatus;
    switch (status) {
      case "processing":
        tagIcon = <SyncOutlined spin />;
        tagColor = "blue";
        showstatus = language === "es" ? "en curso" : "processing"
        break;
      case "processed":
        tagIcon = <CheckCircleOutlined />;
        tagColor = "green";
        showstatus = language === "es" ? "finalizado" : "finished";
        break;
      case "stopped":
        tagIcon = <MinusCircleOutlined />;
        tagColor = "red";
        showstatus = language === "es" ? "cancelado" : "cancelled";
        break;
      case "creating":
        tagIcon = <SyncOutlined spin />;
        tagColor = "yellow";
        showstatus = language === "es" ? "creando" : "creating";
        if (!creatingTimeoutRef.current[idx]) {
          creatingTimeoutRef.current[idx] = true;
          setTimeout(() => {
            props.onUpdateContent();
          }, 10000);
        }
        break;
      default:
        tagIcon = null;
        tagColor = "default";
    }
    return props.isMobile ? <Tag icon={tagIcon} color={tagColor}></Tag> : <Tag icon={tagIcon} color={tagColor}>{showstatus}</Tag>;
  };

  const columns = [
    {
      title: "",
      key: "idx",
      width: 50,
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => item.idx || "",
    },
    {
      title: language === "es" ? "Depósito" : "Warehouse",
      key: "warehouse",
      width: 120,
      render: (item: SweepBase) => item.warehouse?.name || "",
    },
    {
      title: language === "es" ? "Número de Conteo" : "Counting number",
      key: "counting_number",
      width: 120,
      render: (item: SweepBase) => item.counting_number || "",
    },
    {
      title: language === "es" ? "Fecha" : "Date",
      key: "timestamp_start",
      width: 120,
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => item.timestamp_start.slice(0, 11) || "",
    },
    {
      title: language === "es" ? "Dispositivos" : "Devices",
      key: "devices",
      width: 80,
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => item.devices.length,
    },
    {
      title: language === "es" ? "Lecturas" : "Readings count",
      key: "readings_count",
      width: 120,
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => item.readings_count || "0",
    },
    {
      title: language === "es" ? "Estado" : "Status",
      key: "status",
      width: 80,
      render: (item: SweepBase) => getStatusTag(sweepStatus[item.idx] || item.status, item.idx)
    },
    {
      title: language === "es" ? "Acción" : "Action",
      key: "action",
      width: 140,
      render: (item: SweepBase) => (
        <Row gutter={[5, 5]}>
          <Col span={12}>
            {item.status !== "processing" ? <Button
              disabled={item.status !== "processing" || !props.writePermission}
              type="primary"
              style={{ backgroundColor: (item.status === "processing" ? "#73d13d" : "") }}
              icon={<CheckCircleOutlined />}
              shape="round"
              onClick={() => showConfirm("finish", handleEnd, item.idx)}
            /> : <Tooltip title={language === "es" ? "Finalizar" : "Finish"}><Button
              disabled={item.status !== "processing" || !props.writePermission}
              type="primary"
              style={{ backgroundColor: (item.status === "processing" ? "#73d13d" : "") }}
              icon={<CheckCircleOutlined />}
              shape="round"
              onClick={() => showConfirm("finish", handleEnd, item.idx)}
            /></Tooltip>}
          </Col>
          <Col span={12}>
            {item.status !== "processing" ?
              <Button
                disabled={item.status !== "processing" || !props.writePermission}
                type="primary"
                style={{ backgroundColor: (item.status === "processing" ? "#ff4d4f" : "") }}
                icon={<StopOutlined />}
                shape="round"
                onClick={() => showConfirm("cancel", handleStop, item.idx)}
              />
              : <Tooltip title={language === "es" ? "Cancelar" : "Cancel"}><Button
                disabled={item.status !== "processing" || !props.writePermission}
                type="primary"
                style={{ backgroundColor: (item.status === "processing" ? "#ff4d4f" : "") }}
                icon={<StopOutlined />}
                shape="round"
                onClick={() => showConfirm("cancel", handleStop, item.idx)}
              /></Tooltip>}
          </Col>
        </Row>
      ),
    },
    {
      title: language === "es" ? "Detalles" : "Details",
      key: "details",
      width: 120,
      render: (item: SweepBase) => (
        <Link to={`${item.idx}`}>
          {props.isMobile ? <Button type="primary" icon={<InfoCircleOutlined />} shape="round" />
            : <Button type="primary" icon={<InfoCircleOutlined />} shape="round" disabled={item.status === "creating"}>{language === "es" ? "Detalles" : "Details"}
            </Button>}
        </Link>
      ),
    },
    {
      title: "CSV",
      key: "csv",
      width: 80,
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => (
        <Button type="primary"
          // icon={<InfoCircleOutlined />} 
          disabled={item.status === "creating"}
          shape="round"
          onClick={() => handleCSV(String(item.idx))}>
          CSV
        </Button>
      ),
    },
    // {
    //   title: "Revision",
    //   key: "revision",
    //   render: (item: SweepBase) => (
    //     <Link to={`${item.idx}/revision/`}>
    //       <Button type="primary" disabled={item.status === "processed" ? false : true} icon={<FormOutlined />} shape="round">
    //         Revision
    //       </Button>
    //     </Link>
    //   ),
    // },
    {
      title: language === "es" ? "Supervisión" : "Supervision",
      key: "supervision",
      width: 120,
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => (
        item.status === "processing" ?
          <Link to={`../supervision/${item.warehouse?.idx}`}>
            {props.isMobile ? <Button type="primary" disabled={item.status === "processing" ? false : true} icon={<AreaChartOutlined />} shape="round" /> : <Button type="primary" disabled={item.status === "processing" ? false : true} icon={<AreaChartOutlined />} shape="round">
              {language === "es" ? "Supervisión" : "Supervision"}
            </Button>}
          </Link>
          :
          <Link to={`../supervision/history/${item.warehouse?.idx}/${item.idx}`}>
            {props.isMobile ? <Button type="primary" disabled={item.status === "processed" ? false : true} icon={<AreaChartOutlined />} shape="round" /> : <Button type="primary" disabled={item.status === "processed" ? false : true} icon={<AreaChartOutlined />} shape="round">
              {language === "es" ? "Supervisión" : "Supervision"}
            </Button>}
          </Link>
      ),

    },
  ];

  const paginationConfig = {
    current: props.page,
    pageSize: props.pageSize,
    total: props.total,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50"],
    onChange: (page: number, pageSize: number | undefined) => {
      if (pageSize) {
        props.onPageChange(page, pageSize);
      }
    }
  }

  return (
    <Spin
      spinning={props.loading}>

      <Table
        columns={columns}
        dataSource={props.sweeps}
        rowKey="id"
        scroll={{ x: (props.isMobile ? 600 : 1150) }}
        size="small"
        pagination={paginationConfig}
      />
    </Spin>
  );
};

export default SweepsTable;