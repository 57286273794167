import { Button, Card, Col, Collapse, CollapseProps, Divider, Progress, Row, Spin, Statistic, Tag, Timeline } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { DeviceCameraLogs, PalletDeviceDetails } from "../../../../../models/PalletDevice";
import PalletDeviceService from "../../../../../services/PalletDeviceService";
import { Link, useParams } from "react-router-dom";
import { BuildOutlined, CaretLeftOutlined, ClockCircleOutlined, CodeSandboxOutlined, DatabaseOutlined, WifiOutlined } from "@ant-design/icons";
import { BsBattery, BsBatteryFull, BsBatteryHalf } from "react-icons/bs";

const CameraLogs: React.FC = () => {
    const { warehouseIdx, deviceIdx } = useParams();
    const [camLogs, setCamLogs] = useState<DeviceCameraLogs>();
    const [device, setDevice] = useState<PalletDeviceDetails>();
    const cameraIndexes = ["camera_1", "camera_2", "camera_3", "camera_4"];
    const [loading, setLoading] = useState<boolean>(true);

    const updateContent = useCallback(() => {
        if (deviceIdx) {
            PalletDeviceService.getOne(deviceIdx).then((pallet) => {
                if (pallet === null) {
                    return;
                }
                setDevice(pallet);
                setLoading(false)
            });
        }
    }, [deviceIdx]);

    const updateLogs = useCallback(() => {
        if (deviceIdx) {
            PalletDeviceService.getCurrentReadings(parseInt(deviceIdx)).then((camLogs) => {
                if (camLogs === null) {
                    return;
                }
                setCamLogs(camLogs);
            });
        }
    }, [deviceIdx]);

    useEffect(() => {
        updateContent();
        const interval = setInterval(() => {
            updateContent();
        }, 20000);
        return () => clearInterval(interval);
    }, [updateContent]);

    useEffect(() => {
        updateLogs();
        const interval = setInterval(() => {
            updateLogs();
        }, 3000);
        return () => clearInterval(interval);
    }, [updateLogs]);

    const connectionStatusTag = (status: string | undefined) => {
        let icon, color;
        switch (status) {
            case "connected":
                icon = <WifiOutlined />;
                color = "#87d068";
                break;

            case "disconnected":
                icon = <WifiOutlined />;
                color = "#f50";
                break;

            case "connecting":
            default:
                icon = <ClockCircleOutlined />;
                color = "processing";
                break;
        }
        return <Tag style={{ fontSize: "0.85vw" }} icon={icon} color={color}></Tag>;
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
        }, 300); // Adjust this timing if necessary
    };
    
    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: `${device?.name}`,
            children: <iframe
                title="WebRTC Stream"
                src={`https://${device?.name.replace(' ', '').toLowerCase()}.app.devmediaserver.algolabs.ai/live-view`}
                allow="camera; microphone; autoplay"
                style={{
                    width: "100%",
                    height: "87vh",
                }}
            />,
        },
    ];

    return (
        <Spin spinning={loading}>
        <Card title={device?.name}
            extra={<Link to={warehouseIdx?`/supervision/${warehouseIdx}`:`/devices`} >
                <Button
                    type="primary"
                    shape="round"
                    icon={<CaretLeftOutlined />}
                >
                    Back
                </Button>
            </Link>}>
            <Row gutter={[20, 10]} style={{ width: "100%", height: "100%" }}>
                <Col span={12}>
                    {device && <Card>
                        <Row gutter={[0, 0]}>
                            <Col span={24}>
                                <Card bordered={false}>
                                    <Statistic
                                        title="Device pending readings"
                                        value={device.pending_readings_count}
                                    />
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card bordered={false}>
                                    <Statistic
                                        title="Device pending images"
                                        value={device.pending_images_count}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Card>}
                </Col>
                <Col span={12}>
                    {device &&
                        <Card>
                            <Row gutter={[10, 23]} justify="center" align="middle" style={{ overflow: "hidden" }}>
                                <Col span={6}>
                                    <b style={{ fontSize: "0.90vw" }}>Disk:</b>
                                </Col>
                                <Col span={4}>
                                    <Tag style={{ fontSize: "0.85vw" }} icon={<DatabaseOutlined />} color={"#114373"}></Tag>
                                </Col>
                                <Col span={4}></Col>
                                <Col span={10}>
                                    <Progress percent={Math.round(((device.hardware_status ? device.hardware_status.general.disk_status : 0) * 100))} strokeLinecap="butt" steps={10} />
                                </Col>
                                <Col span={6}>
                                    <b style={{ fontSize: "0.90vw" }}>CPU:</b>
                                </Col>
                                <Col span={4}>
                                    <Tag style={{ fontSize: "0.85vw", padding: "-2px" }} icon={<CodeSandboxOutlined />} color={"#114373"}></Tag>
                                </Col>
                                <Col span={4}></Col>
                                <Col span={10}>
                                    {device.hardware_status?.general.cpu_status &&
                                        <Progress percent={Math.round((device.hardware_status?.general.cpu_status * 100))} strokeLinecap="butt" steps={10} />}
                                </Col>
                                <Col span={6}>
                                    <b style={{ fontSize: "0.90vw" }}>RAM:</b>
                                </Col>
                                <Col span={4}>
                                    <Tag style={{ fontSize: "0.85vw" }} icon={<BuildOutlined />} color={"#114373"}></Tag>
                                </Col>
                                <Col span={4}></Col>
                                <Col span={10}>
                                    {device.hardware_status?.general.ram_status &&
                                        <Progress percent={Math.round((device.hardware_status?.general.ram_status * 100))} strokeLinecap="butt" steps={10} />}
                                </Col>
                                <Col span={6}>
                                    <b style={{ fontSize: "0.90vw" }}>Connection:</b></Col>
                                <Col span={4}>
                                    {connectionStatusTag(device.hardware_status?.connection.connection_status)}
                                </Col>
                                <Col span={4}></Col>
                                <Col span={10}>
                                    {device?.hardware_status?.connection.signal_strength !== undefined && (
                                        <Progress
                                            percent={Math.round((device.hardware_status.connection.signal_strength * 100))}
                                            strokeLinecap="butt"
                                            steps={10}
                                            strokeColor={
                                                device.hardware_status.connection.signal_strength > 0.70
                                                    ? "#52c41a"
                                                    : device.hardware_status.connection.signal_strength > 0.3
                                                        ? "#fadb14"
                                                        : "#f5222d"
                                            }
                                        />
                                    )}
                                </Col>
                                <Col span={6}>
                                    <b style={{ fontSize: "0.90vw" }}>Battery:</b>
                                </Col>
                                <Col span={4}>
                                    {device.hardware_status?.battery.battery_level !== undefined ? (
                                        <Tag style={{ fontSize: "0.85vw" }}
                                            icon={
                                                device.hardware_status.battery.battery_level < 0.7
                                                    ? <BsBatteryHalf />
                                                    : device.hardware_status.battery.battery_level < 0.3
                                                        ? <BsBattery />
                                                        : <BsBatteryFull />}
                                            color={"#114373"}></Tag>
                                    ) : <Tag style={{ fontSize: "0.85vw" }}
                                        icon={<BsBattery />} color={"#114373"} />}
                                </Col>
                                <Col span={4}></Col>
                                <Col span={10}>
                                    {device.hardware_status?.battery.battery_level !== undefined && (
                                        <Progress
                                            percent={parseFloat((device.hardware_status.battery.battery_level * 100).toFixed(2))}
                                            strokeLinecap="butt"
                                            steps={10}
                                            strokeColor={
                                                device.hardware_status.battery.battery_level > 0.70
                                                    ? "#52c41a"
                                                    : device.hardware_status.battery.battery_level > 0.3
                                                        ? "#fadb14"
                                                        : "#f5222d"
                                            }
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Card>}
                </Col>
            </Row>
            <Divider>Camera logs</Divider>
            <Row gutter={[20, 20]} >
                {cameraIndexes.map((cameraIndex, i) => (
                    <Col key={i} span={12} >
                        <Card
                            title={`Camera ${i + 1}`}
                            style={{ height: 'auto', display: 'flex', flexDirection: 'column' }} // Ensures card wraps content
                        >
                            <Timeline
                                style={{ marginBottom: "-50px", flexGrow: 1 }} // flexGrow ensures the Timeline grows inside the Card
                                items={
                                    camLogs?.[cameraIndex].map((log) => ({
                                        children: log,
                                        color: "green"
                                    }))
                                }
                            />
                        </Card>

                    </Col>
                ))}
            </Row>
            <Divider>Live view</Divider>
            <Collapse
                collapsible={device?.is_online?"header":"disabled"}
                items={items}
                destroyInactivePanel
                onChange={scrollToBottom}  // Scroll to the bottom when the Collapse panel is opened
            />
        </Card>
        </Spin>
    );
};

export default CameraLogs;
