import axios, { AxiosResponse } from "axios";
import UserService from "./UserService";
import CorridorStateBase from "../models/CorridorState";

const API_BASE_URL = window._env_.REACT_APP_ARCOR_CENTRAL_API_URL;
const API_RESOURCE = "corridor_states/";

export default class CorridorStatesService {
  public static async getAll(sweep_idx: string): Promise<CorridorStateBase[] | null> {
    try {
      const response: AxiosResponse<CorridorStateBase[]> = await axios.get(
        API_BASE_URL + API_RESOURCE + sweep_idx + "/",
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const corridorStates = response.data.map((corridorState) =>
      CorridorStateBase.from(corridorState)
      );
      return corridorStates;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async setExported(idx: string): Promise<string | null> {
    try {
      const response: AxiosResponse<string> = await axios.put(
        API_BASE_URL + API_RESOURCE + idx + "/exported",
        {},
        {
          headers: UserService.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async setDeviceStatusDone(idx: string): Promise<string | null> {
    try {
      const response: AxiosResponse<string> = await axios.put(
        API_BASE_URL + API_RESOURCE + idx + "/device_status",
        {},
        {
          headers: UserService.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
