import { Menu, MenuProps, Modal } from "antd";
import {
  AreaChartOutlined,
  DesktopOutlined,
  FormOutlined,
  PoweroffOutlined,
  PullRequestOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect } from "react";
import UserService from "../../services/UserService";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useLanguage } from "../../context/LanguageContext";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const iconMap: { [key: string]: React.ReactNode } = {
  sweep: <PullRequestOutlined />,
  warehouse: <TableOutlined />,
  racktemplate: <FormOutlined />,
  device: <DesktopOutlined />,
  supervision: <AreaChartOutlined />,
  Logout: <PoweroffOutlined />,
};

const labels: { [key: string]: { [key: string]: string } } = {
  sweep: { en: "Sweeps", es: "Barridos" },
  warehouse: { en: "Warehouses", es: "Depósitos" },
  racktemplate: { en: "Rack Templates", es: "Plantillas de Rack" },
  device: { en: "Devices", es: "Dispositivos" },
  supervision: { en: "Supervision", es: "Supervisión" },
  Logout: { en: "Logout", es: "Cerrar sesión" },
};

const pathMap: { [key: string]: string } = {
  Sweeps: "/sweeps",
  Warehouses: "/warehouses",
  "Rack Templates": "/racktemplates",
  Devices: "/devices",
  Supervision: "/supervision",
  Logout: "",
};

interface LeftMenuProps {
  setCollapsed: (value: boolean) => void;
}

function LeftMenu({ setCollapsed }: LeftMenuProps) {
  const screens = useBreakpoint();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = React.useState<boolean>(false);
  const [sideBar, setSideBar] = React.useState<string[]>([]);
  const location = useLocation();

  const selectedKey = sideBar.findIndex((label) => {
    const path = pathMap[labels[label]["en"]];
    return location.pathname.startsWith(path);
  }).toString() || "0"; // Default to "0" if no match is found.
  
  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "-1") {
      setShowLogoutModal(true);
      return;
    }
    const path = pathMap[labels[sideBar[parseInt(e.key, 10)]]?.["en"]];
    if (path) {
      navigate(path);
      if (!screens.lg) {
        setCollapsed(true);
      }
    }
  };
  

  const logout = () => {
    sessionStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  const updateContent = useCallback(() => {
    UserService.getSideBar().then((sideBar) => {
      if (sideBar && sideBar.length > 0) {
        setSideBar(sideBar);
      } else {
        console.error("Error al obtener los elementos del menú");
        setSideBar([]);  // Optional: could set default or show message
      }
    });
  }, []);

  useEffect(() => {
    updateContent();
  }, [updateContent]);

  const modalTitle = language === "es" ? "Cerrar sesión" : "Logout";
  const modalContent = language === "es" ? "¿Estás seguro de que quieres cerrar sesión?" : "Are you sure you want to logout?";
  const okText = language === "es" ? "Sí" : "Ok";
  const cancelText = language === "es" ? "No" : "Cancel";

  const items = [
    ...sideBar.map((label, index) =>
      getItem(labels[label][language], index.toString(), iconMap[label])
    ),
    getItem(labels["Logout"][language], "-1", iconMap["Logout"]),
  ];

  return (
    <>
      <Menu
        theme="dark"
        onClick={onClick}
        selectedKeys={[selectedKey]}
        mode="inline"
        items={items}
      />
      <Modal
        title={modalTitle}
        open={showLogoutModal}
        onOk={logout}
        onCancel={() => setShowLogoutModal(false)}
        okText={okText}
        cancelText={cancelText}
      >
        <p>{modalContent}</p>
      </Modal>
    </>
  );
}

export default LeftMenu;
