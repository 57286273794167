import React, { useState, useEffect } from "react";
import { Button, Popover, Row } from "antd";
import { LayoutShowSchema } from "../../../../../../models/Warehouse";
import { useLanguage } from "../../../../../../context/LanguageContext";

interface GridProps {
  rackTemplate: LayoutShowSchema;
  containerHeight?: number;
  refreshPreview: boolean;
  setRefreshPreview:(value:boolean)=>void;
  onIgnoredCellsChange: (ignoredCells: Array<[number, number[]]>) => void; // Callback for parent
}

const LayoutPreview: React.FC<GridProps> = ({
  rackTemplate,
  containerHeight,
  onIgnoredCellsChange,
  setRefreshPreview,
  refreshPreview
}) => {
  const { language } = useLanguage();
  // Initialize ignoredCells state based on rackTemplate.ignore_cells
  const [ignoredCells, setIgnoredCells] = useState<Array<[number, number[]]>>(
    () => [...rackTemplate.ignore_cells]
  );
  
  useEffect(() => {
    if(refreshPreview===true){
      setIgnoredCells([]);
      setRefreshPreview(false)
    }
// eslint-disable-next-line
  }, [refreshPreview]);

  useEffect(() => {
    // Pass updated ignoredCells to the parent component
    onIgnoredCellsChange(ignoredCells);
    // eslint-disable-next-line
  }, [ignoredCells]);

  const isCellIgnored = (row: number, col: number): boolean => {
    const column = ignoredCells.find(([c]) => c === col);
    return column ? column[1].includes(row) : false;
  };

  const toggleCellIgnore = (row: number, col: number) => {
    setIgnoredCells(prevIgnoredCells => {
      const newIgnoredCells = [...prevIgnoredCells];
      const columnIndex = newIgnoredCells.findIndex(([c]) => c === col);

      if (columnIndex !== -1) {
        const [column, rows] = newIgnoredCells[columnIndex];
        if (rows.includes(row)) {
          // Remove the row if it's already ignored
          newIgnoredCells[columnIndex] = [column, rows.filter(r => r !== row)];
          // Remove the column if no rows are left
          if (newIgnoredCells[columnIndex][1].length === 0) {
            newIgnoredCells.splice(columnIndex, 1);
          }
        } else {
          // Add the row to the ignored column
          newIgnoredCells[columnIndex] = [column, [...rows, row]];
        }
      } else {
        // Add a new column with the row
        newIgnoredCells.push([col, [row]]);
      }

      return newIgnoredCells;
    });
  };

  const renderGrid = () => {
    const grid = [];
    for (let i = rackTemplate.level_range[1]; i >= rackTemplate.level_range[0]; i--) {
      const row = [];
      const start =
        rackTemplate.orientation === "right"
          ? rackTemplate.column_range[1]
          : rackTemplate.column_range[0];
      const end =
        rackTemplate.orientation === "right"
          ? rackTemplate.column_range[0]
          : rackTemplate.column_range[1];
      const inc = rackTemplate.orientation === "right" ? -1 : 1;
      const compare = (colIter: number, end: number): boolean => {
        return rackTemplate.orientation === "right"
          ? colIter >= end
          : colIter <= end;
      };

      for (let j = start; compare(j, end); j += inc) {
        const colText = String(j).padStart(2, "0");
        const levelText = String(i);
        const positionText = colText + levelText;

        const buttonStyle = {
          margin: "2px",
          backgroundColor: isCellIgnored(i, j) ? "#FF5252" : "#4CAF50",
        };

        row.push(
          <Popover title={language==="es"?`Posición: ${positionText}`:`Position: ${positionText}`} key={`tooltip-${i}-${j}`}>
            <Button
              ghost
              key={`button-${i}-${j}`}
              type={"primary"}
              size="small"
              style={buttonStyle}
              onClick={() => toggleCellIgnore(i, j)} // Toggle cell on click
            ></Button>
          </Popover>
        );
      }
      grid.push(
        <div
          key={`row-${i}`}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {row}
        </div>
      );
    }

    return grid;
  };

  return (
    <Row
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: containerHeight ? containerHeight - 100 : "auto",
        width: "100%",
        overflow: "scroll"
      }}
    >
      {renderGrid()}
    </Row>
  );
};

export default LayoutPreview;
