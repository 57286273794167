import PalletDevice from "./PalletDevice";
import Warehouse from "./Warehouse";

export class ReadingEntryBase {
  idx: number = 0;
  timestamp_pallet: string | undefined = undefined;
  label_pallet: string | undefined = undefined;
  timestamp_position: string = "";
  label_position: string = "";
  is_empty: boolean = true;

  public static from(json: any) {
    return Object.assign(new ReadingEntryBase(), json) as ReadingEntryBase;
  }
}
export class SweepPositionRequest {
  sweep_id: number = 0;
  pos_label: string = "";
  public static from(json: any) {
    return Object.assign(new SweepPositionRequest(), json) as SweepPositionRequest;
  }
}
export default class SweepBase {
  id: string = "";
  idx: number = 0;
  warehouse: Warehouse | undefined = undefined;
  counting_number: string = "";
  timestamp_start: string = "";
  timestamp_end: string = "";
  devices: Array<PalletDevice> = [];
  readings_count: number = 0;
  status: string = "";


  public static from(json: any) {
    return Object.assign(new SweepBase(), json) as SweepBase;
  }
}

export class SweepShow {
  id: string = "";
  idx: number = 0;
  warehouse: Warehouse | undefined = undefined;
  counting_number: string = "";
  timestamp_start: string = "";
  timestamp_end: string = "";
  devices: Array<PalletDevice> = [];
  readings_count: number = 0;
  status: string = "";
  // readings: Array<ReadingEntryBase> = [];
  positions: Array<SweepPositionShow> = [];

  public static from(json: any) {
    return Object.assign(new SweepShow(), json) as SweepShow;
  }
}

export class SweepCreate {
  timestamp_start: string = "";
  warehouse_idx: number = -1;
  counting_number: string = "";
  devices_idx: Array<number> = [];

  public static from(json: any) {
    return Object.assign(new SweepCreate(), json) as SweepCreate;
  }
}

export class SweepInfo {
  idx: number = 0;
  counting_number: string = "";
  warehouse_name: string = "";
  status: string = "";
  timestamp_start: string = "";

  public static from(json: any) {
    return Object.assign(new SweepInfo(), json) as SweepInfo;
  }
}

export class SweepPaginated {
  page: number = 0;
  page_size: number = 0;
  total_pages: number = 0;
  total_count:number=0;
  results: Array<SweepBase> = []; 

  public static from(json: any) {
    return Object.assign(new SweepPaginated(), json) as SweepPaginated;
  }
}


export class ReadingUpdateModel {
  position_label: string = "";
  label: string = "";
  is_empty: boolean = true;
  sweep_idx: number = -1;

  public static from(json: any) {
    return Object.assign(new ReadingUpdateModel(), json) as ReadingUpdateModel;
  }
}
export class SweepPositionShow {
  pallet_label: string = "";
  position_label: string = "";
  state: string = "";
  public static from(json: any) {
    return Object.assign(new SweepPositionShow(), json) as SweepPositionShow;
  }
}
export class SweepBigPictureRequest {
  sweep_idx: string = "";
  corridor_name: string = "";
  range_x: number=0;
  range_y: number=0;
  central_x: number=0;
  central_y: number=0;
  public static from(json: any) {
    return Object.assign(new SweepBigPictureRequest(), json) as SweepBigPictureRequest;
  }
}
export class VideoPosition {
  idx: number=0;
  position_label: string = "";
  status: string = "";
  public static from(json: any) {
    return Object.assign(new VideoPosition(), json) as VideoPosition;
  }
}