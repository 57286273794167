import React, { useCallback, useContext, useEffect } from "react";
import { Button, Col, DatePicker, Input, Modal, Row, Select, notification } from "antd";
import { useState } from "react";
import SweepBase, { SweepCreate } from "../../../../../models/Sweep";
import SweepService from "../../../../../services/SweepService";
import { WarehouseSummary } from "../../../../../models/Warehouse";
import PalletDevice from "../../../../../models/PalletDevice";
import dayjs from "dayjs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import UserContext from "../../../../../context/UserContext";
import { useLanguage } from "../../../../../context/LanguageContext";
import WarehouseService from "../../../../../services/WarehouseService";

interface CreateSweepsModalProps {
  isVisible: boolean;
  onClose: () => void;
  onUpdateContent: () => void;
  warehouses: WarehouseSummary[];
  devices: PalletDevice[];
  nowProcessing: number[];
  sweeps: SweepBase[];
  onSubmitLoading: (state: boolean) => void;
}

const SweepsCreate: React.FC<CreateSweepsModalProps> = ({
  isVisible,
  onClose,
  onUpdateContent,
  warehouses,
  devices,
  nowProcessing,
  sweeps,
  onSubmitLoading
}) => {
  const  isMobile  = useContext(UserContext).isMobile; 
  const { language } = useLanguage();
  const [counting_number, setCounting_number] = useState<string>("");
  const [warehouseIdx, setWarehouseIdx] = useState<number | undefined>(undefined);
  const [devicesIdxs, setDevicesIdxs] = useState<number[]>([]);
  const [timestamp_start, setTimestamp_start] = useState<string>("");
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [filteredDevices, setFilteredDevices] = useState<PalletDevice[]>([]);
  const [layoutStatus, setLayoutStatus] = useState<string>("");

  const onDevicesChange = (values: number[]) => {
    setDevicesIdxs(values);
  };

  const onWarehouseChange = (value: number) => {
    setDevicesIdxs([]);
    setWarehouseIdx(value);
    const filtered = devices.filter((device) => device.warehouse?.idx === value);
    setFilteredDevices(filtered);
  };

  const updateContent = useCallback(() => {
    if (warehouseIdx){
      WarehouseService.getWarehouseLayoutStatus(warehouseIdx).then((response) => {
        if (response){
          setLayoutStatus(response)
        } else {
          notification.error({
            message: language === "es" ? "Se produjo un error al intentar ver el estado del layout" : "There was an error trying to view the layout status",
          })
        }
      })
    }
  },[warehouseIdx]);

  useEffect(() => {
    if (warehouseIdx){
      updateContent();
    }
      const intervalId = setInterval(() => {
        if (warehouseIdx){
          updateContent();
        }
      }, 5000);
      return () => clearInterval(intervalId);
  }, [updateContent]);

  const handleChangeCounting_number = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCounting_number(event.target.value);
  };

  const handleChangeTimestamp_start = (date: dayjs.Dayjs | null, dateString: string) => {
    const formattedDate = date ? date.format("DD/MM/YYYY") : "";
    setTimestamp_start(formattedDate);
  };

  const resetFields = () => {
    setCounting_number("");
    setWarehouseIdx(undefined);
    setDevicesIdxs([]);
    setTimestamp_start("");
    setFilteredDevices([]);
  };

  const handleOk = () => {
    const isDuplicateCN = sweeps.some(
      (sweep) =>
        String(sweep.counting_number) === counting_number
    );
    if (isDuplicateCN) {
      Modal.error({
        title: language==="es"?"Número de Conteo Duplicado":"Duplicate Counting Number",
        content:
        language==="es"?"Ya existe un barrido con el mismo número de conteo. Elija un número de conteo diferente.":"A Sweep with the same counting number already exists. Please choose a different counting number.",
      });
    } else {
      const sweep = new SweepCreate();
      sweep.counting_number = counting_number;
      sweep.warehouse_idx = warehouseIdx ?? 0;
      sweep.devices_idx = devicesIdxs;
      sweep.timestamp_start = timestamp_start;
      console.log(sweep);
      onSubmitLoading(true)
      setDisableButton(true)
      SweepService.create(sweep).then((res) => {
        if (res) {
          setDisableButton(false)
          onSubmitLoading(false)
          notification.success({
            message: language==="es"?"Barrido creado con exito":"Sweep created successfully",
          });
          resetFields();
        }
        onUpdateContent();
        onClose();
      });
    };
  };

  const handleCancel = () => {
    resetFields();
    onClose();
  };

  return (
    <Modal
      title={language==="es"?"Crear Barrido":"Create Sweep"}
      open={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={isMobile ? "80%" :"50%"}
      footer={[
        <>
          {nowProcessing.includes(warehouseIdx ?? 0) && (
            <h5 style={{ textAlign: "left", color: "red" }}>
              <ExclamationCircleOutlined /> {language==="es"?"Actualmente, el depósito se encuentra operando":"Currently, the warehouse is in operation."}
            </h5>
          )}
          {layoutStatus === "Layout Updating" && 
            <h5 style={{ textAlign: "left", color: "red" }}>
              <ExclamationCircleOutlined /> {language === "es" ? "Actualmente, Se esta actualizando el layout": "Currently, the layout is being updated"}
            </h5>}
          <Button key="Back" onClick={handleCancel} shape="round">
          {language==="es"?"Cancelar":"Cancel"}
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            shape="round"
            disabled={
              !warehouseIdx ||
              devicesIdxs.length === 0 ||
              !counting_number ||
              !timestamp_start ||
              nowProcessing.includes(warehouseIdx ?? 0) ||
              layoutStatus === "Layout Updating" ||
              disableButton 
            }
          >
            {language==="es"?"Enviar":"Submit"}
          </Button>
        </>
      ]}
    >
      <Row gutter={[16,10]}>
      <Col xs={24} sm={12} md={12} lg={6}>
        <Select
          placeholder={language==="es"?"Seleccione un Depósito":"Select a Warehouse"}
          style={{ width: '100%' }}
          onChange={onWarehouseChange}
          popupMatchSelectWidth={false}
          options={warehouses.map((warehouse) => ({
            label: warehouse.name,
            value: warehouse.idx,
          }))}
          value={warehouseIdx}
        />
      </Col>
      <Col xs={24} sm={12} md={12} lg={6}>
        <Select
          mode="multiple"
          placeholder={language==="es"?"Seleccione Dispositivos":"Select Devices"}
          style={{ width: '100%' }}
          showSearch={false}
          onChange={onDevicesChange}
          popupMatchSelectWidth={false}
          options={filteredDevices.map((device) => ({
            label: device.name,
            value: device.idx,
          }))}
          value={devicesIdxs}
        />
      </Col>
      <Col xs={24} sm={12} md={12} lg={6}>
        <Input
          type="text"
          style={{ width: '100%' }}
          placeholder={language==="es"?"Número de Conteo":"Counting Number"}
          onChange={handleChangeCounting_number}
          value={counting_number}
        />
      </Col>
      <Col xs={24} sm={12} md={12} lg={6}>
        <DatePicker
          format="DD/MM/YYYY"
          style={{ width: '100%' }}
          onChange={handleChangeTimestamp_start}
          value={timestamp_start ? dayjs(timestamp_start, "DD/MM/YYYY") : null}
        />
      </Col>
    </Row>
    </Modal>
  );
};

export default SweepsCreate;
