import { Input, Col, Row, Space, Checkbox, Select } from "antd";
import { useState, useCallback, useEffect } from "react";
import { useLanguage } from "../../../../../context/LanguageContext";

interface PositionsControlsProps {
  onApply: (
    deviceName: string[],
    corridorName: string,
    displayUnexploreds: boolean
  ) => void;
  devicesNames: string[];
}

const CorridorsControls = (props: PositionsControlsProps) => {
  const { language } = useLanguage();
  const [devicesNames, setDevicesNames] = useState<string[]>([]);
  const [deviceName, setDeviceName] = useState<string[]>([]);
  const [corridorName, setCorridorName] = useState<string>("");
  const [displayUnexploreds, setDisplayUnexploreds] = useState<boolean>(false);

  const onApply = useCallback(() => {
    props.onApply(deviceName, corridorName, displayUnexploreds);
  }, [props, deviceName, corridorName, displayUnexploreds]);

  useEffect(() => {
    onApply();
  }, [onApply]);

  useEffect(() => {
    const updateContent = () => setDevicesNames(props.devicesNames);
    updateContent();
    const interval = setInterval(updateContent, 600000);
    return () => clearInterval(interval);
  }, [props.devicesNames]);

  const onDeviceChange = (values: string[]) => {
    setDeviceName(values);
  };

  return (
    <Row gutter={[20, 20]} align="middle" style={{ padding: "10px 0" }}>
      <Col xs={24} sm={8} md={8} lg={6}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <span>{language === "es" ? "Dispositivos:" : "Devices:"}</span>
          <Select
            mode="multiple"
            placeholder={language === "es" ? "Dispositivos" : "Devices"}
            style={{ width: "100%" }}
            onChange={onDeviceChange}
            showSearch={false}
            options={devicesNames.map((deviceName) => ({
              label: deviceName,
              value: deviceName,
            }))}
          />
        </Space>
      </Col>
      <Col xs={24} sm={8} md={8} lg={6}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <span>{language === "es" ? "Pasillo:" : "Corridor:"}</span>
          <Input
            placeholder={language === "es" ? "Pasillo" : "Corridor"}
            value={corridorName}
            onChange={(e) => setCorridorName(e.target.value)}
          />
        </Space>
      </Col>
      <Col xs={24} sm={8} md={8} lg={6}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <span>{language === "es" ? "Solo exploradas:" : "Just explored:"}</span>
          <Checkbox
            checked={displayUnexploreds}
            onChange={(e) => setDisplayUnexploreds(e.target.checked)}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default CorridorsControls;
