import React from "react";
import { Col, Divider, Empty, Progress, Row, Spin, Tag } from "antd";
import Supervision from "../../../../../models/Supervision";
import { BsBattery, BsBatteryFull, BsBatteryHalf } from "react-icons/bs";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useLanguage } from "../../../../../context/LanguageContext";

interface CorridorInfoProps {
  supervision: Supervision
  loading: boolean;
  parentWidth: number;
  parentHeight: number;
}

const CorridorInfo: React.FC<CorridorInfoProps> = ({ supervision, loading, parentHeight, parentWidth }) => {
  const { language } = useLanguage();
  const screens = useBreakpoint();
  return (
    <Spin spinning={loading}>
      <Row gutter={[10, 10]} justify="center" align="middle" style={{ overflow: "hidden" }} >
        <Col span={12} style={{ textAlign: 'center' }} >
          <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{language==="es"?"Lecturas:":"Readings:"}</b>
        </Col>
        <Col span={12} style={{ textAlign: 'center' }}>
          <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{language==="es"?"Efectividad:":"Effective:"}</b>
        </Col>
        <Col span={12} style={{ textAlign: 'center' }}><Progress type="circle" percent={parseFloat((supervision.reading_percentage * 100).toFixed(2))}
          format={(percent) => `${percent}%`} size={80} success={{ progress: -1 }} /></Col>
        <Col span={12} style={{ textAlign: 'center' }}><Progress type="circle" percent={parseFloat((supervision.effective_percentage * 100).toFixed(2))}
          format={(percent) => `${percent}%`} size={80}
          strokeColor={supervision.effective_percentage > 0.75 ? "#1677ff" : supervision.effective_percentage > 0.50 ? "#fadb14" : "#f5222d"}
          success={{ progress: -1 }} />
        </Col>
        <Col span={12} style={{ textAlign: 'center' }} >
          <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{`${supervision.read_positions} / ${supervision.total_count}`}</b>
        </Col>
        <Col span={12} style={{ textAlign: 'center' }}>
          <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{`${supervision.effective_readings} / ${supervision.read_positions}`}</b>
        </Col>
        <Divider></Divider>
        {supervision.read_positions > 0 ? (<>
        <Col span={4} style={{ textAlign: 'center', marginBottom: 8 }}>
          <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{language==="es"?"Dispositivo:":"Device:"}</b>
        </Col>
        <Col span={3} style={{ textAlign: 'center', marginBottom: 8}}></Col>
        <Col span={17} style={{ textAlign: 'center', marginBottom: 8}}><b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{(supervision.device_name)}</b></Col>
        <Col span={4} style={{ textAlign: 'center', marginBottom: 8}}>
          <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{language==="es"?"Batería:":"Battery:"}</b>
        </Col>
          <Col span={3} style={{ textAlign: 'center', marginBottom: 8}}>
            {supervision.device_name !== "" ?
              <Tag style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}
                icon={
                  supervision.battery_level * 100 < 70
                    ? <BsBatteryHalf />
                    : supervision.battery_level * 100 < 30
                      ? <BsBattery />
                      : <BsBatteryFull />}
                color={"#114373"}></Tag>
              : <Tag style={{ fontSize: "0.85vw" }}
                icon={<BsBattery />}
                color={"#114373"} />}
          </Col>
          <Col span={17} style={{ textAlign: 'center', marginBottom: 8}}>
            <Progress percent={parseFloat((supervision.battery_level * 100).toFixed(2))}
              strokeLinecap="butt"
              steps={10}
              size={[parentWidth / 17 - 7, parentHeight / 20]}
              strokeColor={supervision.battery_level < 0.60 ? "#fadb14" : supervision.battery_level < 0.3 ? "#f5222d" : "default"} />
          </Col>
        </> 
        ): (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{maxHeight: 15}}
            imageStyle={{ height: 40, marginBottom: 0}} description={false}/>
        )}
      </Row>
    </Spin>

  );
};

export default CorridorInfo;
