import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Card, Spin, Divider, Button, Row, Col, Modal, notification, Popover, } from "antd";
import WarehouseService from "../../../../../services/WarehouseService";
import LayoutColorReferences from "./LayoutColorReferences";
import { useNavigate, useParams } from "react-router-dom";
import WarehouseShow, { LayoutShowSchema } from "../../../../../models/Warehouse";
import { CaretLeftOutlined, CheckCircleOutlined, InfoCircleOutlined, SettingOutlined, StopOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import RackTemplateMap from "./RackTemplateMap";
import LayoutConfigModal from "./modal/LayoutConfigModal";
import { useLanguage } from "../../../../../context/LanguageContext";

const { Panel } = Collapse;

const WarehouseLayout: React.FC = () => {
  const { language } = useLanguage();
  const [rackTemplates, setRackTemplates] = useState(new Array<LayoutShowSchema>());
  const [modalState, setModalState] = useState<{ [key: string]: boolean }>({});
  const [isVisible,setIsVisible]=useState<boolean>(false)
  const [isVisibleBack,setIsVisibleBack]=useState<boolean>(false) 
  const [rackTemplatesToSubmit, setRackTemplatesToSubmit] = useState<Array<LayoutShowSchema>>([])
  const { warehouseId } = useParams();
  const screens = useBreakpoint();
  const [warehouse, setWarehouse] = useState(new WarehouseShow());
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const updateContent = useCallback(() => {
    if (warehouseId) {
      WarehouseService.getOne(warehouseId, navigate).then((res) => {
        if (res === null) {
          return;
        }
        setWarehouse(res);
        setLoading(false);
      });
      WarehouseService.getLayouts(warehouseId).then((layouts) => {
        if (layouts == null) {
          return;
        }
        setRackTemplates(layouts);
        setLoading(false);
        const modalState = rackTemplates.reduce((acc, template) => {
          acc[template.template_name] = false;
          return acc;
        }, {} as { [key: string]: boolean });
        setModalState(modalState);
      });
    }// eslint-disable-next-line
  }, [warehouseId, navigate]);
  const addOrReplaceRackTemplate = (newTemplate: LayoutShowSchema) => {
    setRackTemplatesToSubmit((prevTemplates) => {
      // Check if a template with the same name already exists
      const existingIndex = prevTemplates.findIndex(
        (template) => template.template_name === newTemplate.template_name
      );
  
      if (existingIndex !== -1) {
        // Replace the existing template
        const updatedTemplates = [...prevTemplates];
        updatedTemplates[existingIndex] = newTemplate;
        return updatedTemplates;
      } else {
        // Add the new template
        return [...prevTemplates, newTemplate];
      }
    });
  };
  console.log(rackTemplatesToSubmit)

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);

  const showModal = (templateName: string) => {
    setModalState((prevState) => ({
      ...prevState,
      [templateName]: true,
    }));
  };

  const closeModal = (templateName: string) => {
    setModalState((prevState) => ({
      ...prevState,
      [templateName]: false,
    }));
  };
  const handleCancel = () => {
    setIsVisible(false) 
  };
  const handleDiscard = () => {
    setRackTemplatesToSubmit([])
    window.location.reload()
    setIsVisible(false) 
  };
  const handleBack = () => {
    if(rackTemplatesToSubmit.length>0){
      setIsVisibleBack(true)
    }else{navigate(`/warehouses/${warehouseId}`)}
  };
  const handleSubmit = () => {
   if(warehouseId){
     WarehouseService.submitLayouts(warehouseId,rackTemplatesToSubmit).then((response)=>{
      if(response!=null){
        notification.success({
          message: language==="es"? "Layouts cargados exitosamente":"Layouts loaded succesfully",
        })
        navigate(`/warehouses/${warehouseId}`)
      }else{
        notification.error({
          message: language==="es"? "Se produjo un error al intentar cargar Layouts":"There was an error trying to load layouts",
        })
      }
     })
   }
  };
  const handleAccept = () => {
    navigate(`/warehouses/${warehouseId}`)
  };

  return (
    <Spin spinning={loading}>
      <Card
        title={
          (!screens.lg ? true : false)
            ? warehouse.name + " - " + warehouse.warehouse_number
            : language==="es"? "Déposito: " + warehouse.name + " - " + warehouse.warehouse_number :"Warehouse: " + warehouse.name + " - " + warehouse.warehouse_number
        }
        extra={<Row gutter={[10,1]}>
          <Col>
            <Button danger type="primary" disabled={rackTemplatesToSubmit.length>0?false:true} shape="round" icon={<StopOutlined />}onClick={()=>setIsVisible(true)}>
              {language==="es"? "Descartar Cambios":"Discard Changes"}
            </Button></Col>
          <Col><Button onClick={handleSubmit}  type="primary" disabled={rackTemplatesToSubmit.length>0?false:true} style={{backgroundColor:rackTemplatesToSubmit.length>0?"#73d13d":"#edeff2"}} shape="round" icon={<CheckCircleOutlined />}>
            {language==="es"? "Entregar":"Submit"}
          </Button></Col>
          <Col>          
              <Button type="primary" shape="round" icon={<CaretLeftOutlined />}
              onClick={handleBack}>
                {language==="es"? "Atrás":"Back"}
              </Button>
          </Col>
        </Row>
        }
        style={{
          margin: "20px",
          padding: "20px",
        }}
      >
        <Modal
        destroyOnClose={true}
        title={language==="es"? "¿Está seguro de que desea descartar todos los cambios realizados en el diseño del almacén?":"Are you sure you want to discard all changes made to warehouse's layout?"}
        open={isVisible}
        onCancel={handleCancel}
        footer={
                <>
                  <Button key="Back" onClick={handleCancel} shape="round">
                    {language==="es"? "Cancelar":"Cancel"}
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleDiscard}
                    shape="round"
                  >
                    {language==="es"? "Descartar":"Discard"}
                  </Button>
                </>
              }></Modal>
              <Modal
        destroyOnClose={true}
        title={language==="es"? "Tienes cambios no enviados, ¿quieres descartar los cambios y volver a los almacenes?":"You have unsubmited changes, do you want to discard the changes and go back to warehouses?"}
        open={isVisibleBack}
        onCancel={()=>setIsVisibleBack(false)}
        footer={
                <>
                  <Button key="Back" onClick={()=>setIsVisibleBack(false)} shape="round">
                    {language==="es"? "Cancelar":"Cancel"}
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleAccept}
                    shape="round"
                  >
                    {language==="es"? "Aceptar":"Accept"}
                  </Button>
                </>
              }></Modal>
        <LayoutColorReferences />
        <Divider orientation="center">{language==="es"? "Pasillos":"Corridors"}</Divider>
        <Collapse  destroyInactivePanel accordion>
          {rackTemplates.map((rackTemplate) => {
            const templateToSend =
            rackTemplatesToSubmit.find(
              (template) => template.template_name === rackTemplate.template_name
            ) || rackTemplate;
            
            return(<Panel
              header={language==="es"? `Pasillo ${rackTemplate.template_name}`:`Corridor ${rackTemplate.template_name}`}
              key={rackTemplate.template_name}
              extra={<Row gutter={[10,1]}>
                <Col>
                {rackTemplatesToSubmit.find(
              (template) => template.template_name === rackTemplate.template_name
            )&&<Popover content={language==="es"? "Cambios no enviados para el pasillo":"Unsubmitted changes for the corridor"}><InfoCircleOutlined  style={{color: "#faad14", fontSize: "26px"}}/></Popover>}
                </Col>
                <Col>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<SettingOutlined />}
                  onClick={() => showModal(rackTemplate.template_name)}
                  ></Button>
                  </Col>
                  </Row>
              }
            >
              <Card>
                {warehouseId && (
                  <RackTemplateMap rackTemplate={templateToSend} />
                )}
              </Card>
            </Panel>)
          })}
        </Collapse>
        {rackTemplates.map((rackTemplate) => {
  // Find the rackTemplate in rackTemplatesToSubmit by template_name
  const templateToSend =
    rackTemplatesToSubmit.find(
      (template) => template.template_name === rackTemplate.template_name
    ) || rackTemplate;

  return (
    <LayoutConfigModal
      key={rackTemplate.template_name}
      isVisible={modalState[rackTemplate.template_name]}
      onClose={() => closeModal(rackTemplate.template_name)}
      onUpdateContent={updateContent}
      onRackTemplateToSubmit={addOrReplaceRackTemplate}
      rackTemplate={templateToSend} // Send the updated template data to the modal
    />
  );
})}

      </Card>
    </Spin>
  );
};

export default WarehouseLayout;
