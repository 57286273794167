import { Button, Table } from "antd";
import { Breakpoint } from "antd";
import { Link } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { WarehouseSummary } from "../../../../../models/Warehouse";
import WarehouseService from "../../../../../services/WarehouseService";
import { useLanguage } from "../../../../../context/LanguageContext";

interface WarehousesTableProps {
  warehouses: WarehouseSummary[];
  onSort?: (column_key: string, order: string) => void;
  isMobile: boolean;
}

const WarehousesTable = (props: WarehousesTableProps) => {
  const { language } = useLanguage();
  const columns = [
    {
      title: "",
      key: "idx",
      responsive: ["lg"] as Breakpoint[],
      render: (item: WarehouseSummary) => item.idx,
    },
    {
      title: language === "es" ? "Nombre" : "Name",
      key: "name",
      render: (item: WarehouseSummary) => item.name,
    },
    {
      title: language === "es" ? "Número" : "Number",
      key: "number",
      responsive: ["lg"] as Breakpoint[],
      render: (item: WarehouseSummary) => item.warehouse_number,
    },
    {
      title: language === "es" ? "Pasillos" :"Corridors",
      key: "corridors",
      responsive: ["lg"] as Breakpoint[],
      render: (item: WarehouseSummary) => item.corridors.toString(),
    },
    {
      title: language === "es" ? "Dispositivos" :"Devices",
      key: "devices",
      responsive: ["lg"] as Breakpoint[],
      render: (item: WarehouseSummary) => item.devices.toString(),
    },
    {
      title: language === "es" ? "Posiciones Totales" :"Total Positions",
      key: "positions",
      responsive: ["lg"] as Breakpoint[],
      render: (item: WarehouseSummary) => item.positions.toString(),
    },
    {
      title: language === "es" ? "Posiciones vacías" : "Empty Positions",
      key: "empty_positions",
      responsive: ["lg"] as Breakpoint[],
      render: (item: WarehouseSummary) => item.empty_positions.toString(),
    },
    {
      title: language === "es" ? "Posiciones llenas" :"Full Positions",
      key: "full_positions",
      responsive: ["lg"] as Breakpoint[],
      render: (item: WarehouseSummary) => item.full_positions.toString(),
    },
    {
      title: language === "es" ? "Posiciones Inexploradas" : "Unexplored Positions",
      key: "unknown_positions",
      responsive: ["lg"] as Breakpoint[],
      render: (item: WarehouseSummary) => item.unknown_positions.toString(),
    },
    {
      title: language === "es" ? "Detalles" :"Details",
      key: "details",
      render: (item: WarehouseSummary) => (
        <Link to={`${item.idx}`}>
          {props.isMobile ? <Button type="primary" icon={<InfoCircleOutlined />} shape="round"/> : <Button type="primary" icon={<InfoCircleOutlined />} shape="round">
            {language === "es"? "Detalles" :"Details"}
          </Button>}
        </Link>
      ),
    },
    {
      title: "CSV",
      key: "csv",
      responsive: ["lg"] as Breakpoint[],
      render: (item: WarehouseSummary) => (
        <Button type="primary"
          // icon={<InfoCircleOutlined />} 
          shape="round"
          onClick={()=>WarehouseService.exportCSV(String(item.idx))}>
          CSV
        </Button>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={props.warehouses}
      rowKey="id"
      scroll={{ x: true }}
      size="small"
      pagination={false}
    />
  );
};

export default WarehousesTable;