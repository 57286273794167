import React from "react";
import { Button, Card, Col, Form, Input, InputNumber, Row, Modal } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { useState } from "react";
import LayoutPreview from "./LayoutPreview";
import { LayoutShowSchema } from "../../../../../../models/Warehouse";
import { useLanguage } from "../../../../../../context/LanguageContext";

const equals = (layout1: LayoutShowSchema, layout2: LayoutShowSchema) => {
  return (
    layout1.template_name === layout2.template_name &&
    layout1.level_range[0] === layout2.level_range[0] &&
    layout1.level_range[1] === layout2.level_range[1] &&
    layout1.column_range[0] === layout2.column_range[0] &&
    layout1.column_range[1] === layout2.column_range[1] &&
    layout1.orientation === layout2.orientation &&
    JSON.stringify(layout1.ignore_cells) === JSON.stringify(layout2.ignore_cells)
  );
}

interface LayoutConfigModalProps {
  isVisible: boolean;
  onClose: () => void;
  onUpdateContent: () => void;
  rackTemplate: LayoutShowSchema;
  onRackTemplateToSubmit:(value:LayoutShowSchema)=>void
}

const LayoutConfigModal: React.FC<LayoutConfigModalProps> = ({
  isVisible,
  onClose,
  onUpdateContent,
  rackTemplate,
  onRackTemplateToSubmit
}) => {
  const { language } = useLanguage();
  const [newRackTemplate, setNewRackTemplate] = useState<LayoutShowSchema>(rackTemplate);
  const [clickedButton, setClickedButton] = useState<string | null>(null);
  const onIgnoredCellsChange = (newIgnoredCells: Array<[number, number[]]>) => {
    setNewRackTemplate((prevTemplate) => ({
      ...prevTemplate,
      ignore_cells: newIgnoredCells,
    }));
  };
  const[refreshPreview,setRefreshPreview] = useState<boolean>(false)
  const [form] = Form.useForm(); 
  const onFinish = (values: any) => {
    const { level_range, column_range, ignore_cells } = values;
    const racktemplate = new LayoutShowSchema();
    racktemplate.template_name = rackTemplate.template_name;
    racktemplate.level_range = level_range;
    racktemplate.column_range = column_range;
    racktemplate.orientation = rackTemplate.orientation;

    racktemplate.ignore_cells = ignore_cells
      ? ignore_cells.map((cell: [number, string]) => {
          const [column, rows] = cell;
          const rowArray = rows.split(",").map((row) => parseInt(row.trim(), 10));
          return [column, rowArray];
        })
      : rackTemplate.ignore_cells;

    if (clickedButton === "loadPreviewButton") {
      setRefreshPreview(true)
      setNewRackTemplate(racktemplate);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setNewRackTemplate(rackTemplate);
    onClose(); 
  };
  const handleSubmit = () => {
    if (!equals(rackTemplate,newRackTemplate)){
      onRackTemplateToSubmit(newRackTemplate); 
    } 
    onClose();
  };
  return (
    <Modal
      destroyOnClose={true}
      title={language==="es"? rackTemplate.template_name + " Configuración" :rackTemplate.template_name + " Configuration"}
      open={isVisible}
      onCancel={handleCancel}
      width={"80%"}
      footer={
        <>
          <Button key="Back" onClick={handleCancel} shape="round">
            {language==="es"?"Cancelar":"Cancel"}
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            shape="round"
          >
            {language==="es"?"Confirmar":"Confirm"}
          </Button>
        </>
      }
    >
      <Row gutter={[16, 16]} justify="center" align="top">
        <Col  style={{ textAlign: "center" }}>
          <Card>
            <Form
              form={form} // Asocia el formulario al hook
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{
                "level_range": [rackTemplate.level_range[0], rackTemplate.level_range[1]],
                "column_range": [rackTemplate.column_range[0], rackTemplate.column_range[1]]
              }}
            >
              <Row>
                <Col span={11}>
                <Form.Item
                    label={language==="es"? "Rango de nivel":"Level Range"}
                    name="level_range"
                    rules={[
                      { required: true, message: language==="es"? "Por favor ingrese un rango de nivel": "Please enter level range" },
                    ]}
                  >
                    <Input.Group compact style={{ display: "flex", gap: "4px" }}>
                      <Form.Item
                        name={["level_range", 0]}
                        noStyle
                        rules={[
                          { required: true, message: language==="es"? "Por favor ingrese un nivel mínimo":"Please enter min level" },
                        ]}
                      >
                        <InputNumber placeholder={language==="es"?"Nivel mínimo":"Min Level"} min={1} max={12} style={{ flex: 1, width: "100%"  }} />
                      </Form.Item>
                      <Form.Item
                        name={["level_range", 1]}
                        noStyle
                        rules={[
                          { required: true, message: language==="es"? "Por favor ingrese un nivel máximo":"Please enter max level" },
                        ]}
                      >
                        <InputNumber placeholder={language==="es"? "Nivel máximo":"Max Level"} min={1} max={12} style={{ flex: 1, width: "100%"  }} />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label={language==="es"? "Rango de columnas":"Column Range"}
                    name="column_range"
                    rules={[
                      { required: true, message: language==="es"? "Por favor ingrese un rango de columna":"Please enter column range" },
                    ]}
                  >
                    <Input.Group compact style={{ display: "flex", gap: "4px" }}>
                      <Form.Item
                        name={["column_range", 0]}
                        noStyle
                        rules={[
                          { required: true, message: language==="es"? "Por favor ingrese la columna mínima":"Please enter min column" },
                        ]}
                      >
                        <InputNumber placeholder={language==="es"? "Columna mínima":"Min Column"} min={1} max={60} style={{ flex: 1, width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name={["column_range", 1]}
                        noStyle
                        rules={[
                          { required: true, message: language==="es"? "Por favor ingrese la columna máxima":"Please enter max column" },
                        ]}
                      >
                        <InputNumber placeholder={language==="es"?"Columna máxima":"Max Column"} min={1} max={100} style={{ flex: 1, width: "100%" }} />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={2} style={{ display: "flex", justifyContent: "center" }}>
                  <Form.Item style={{ textAlign: "center", marginBottom: 0 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SyncOutlined />}
                      onClick={() => setClickedButton("loadPreviewButton")}
                    >
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={24}>
          <Card title={language==="es"? "Vista previa":"Preview"}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {newRackTemplate ? (
                <LayoutPreview rackTemplate={newRackTemplate} refreshPreview={refreshPreview} setRefreshPreview={setRefreshPreview} onIgnoredCellsChange={onIgnoredCellsChange}/>
              ) : null}
            </div>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default LayoutConfigModal;
