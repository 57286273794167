import React from "react";
import { Tag, Divider } from "antd";
import { useLanguage } from "../../../../../../context/LanguageContext";

const CorridorColorReferences = () => {
  const { language } = useLanguage();
  return (
    <div style={{ textAlign: "center" }}>
      <Divider orientation="center">{language === "es" ? "Referencia de colores":"Color References"}</Divider>
      <Tag color="#4CAF50">{language === "es" ? "Llena":"Full"}</Tag>
      <Tag color="#a0d911">{language === "es" ? "Vacia":"Empty"}</Tag>
      <Tag color="#FF5252">{language === "es" ? "Inexplorada":"Unexplored"}</Tag>
      <Tag color="#bfbfbf">{language === "es" ? "Ignorada":"Ignored"}</Tag>
    </div>
  );
};

export default CorridorColorReferences;