import { Button, Card, Col, Collapse, Divider, Empty, notification, Progress, Row, Spin, Statistic } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CorridorInfo from "./CorridorInfo";
import SupervisionService from "../../../../../services/SupervisionService";
import Supervision, { SupervisionWarehouse } from "../../../../../models/Supervision";
import { CaretLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { PalletDevicePendingCount } from "../../../../../models/PalletDevice";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useLanguage } from "../../../../../context/LanguageContext";
import CorridorsControls from "./CorridorsControls";

const Corridor: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { warehouseIdx } = useParams();
  const [supervisionWarehouse, setSupervisionWarehouse] = useState<SupervisionWarehouse>();
  const [supervisions, setsupervisions] = useState<Supervision[]>([]);
  const [parentSizes, setParentSizes] = useState<Record<number, { width: number, height: number }>>({});
  const [corridorsLoading, setCorridorsLoading] = useState<boolean>(true)
  const [devicesPendingCount, setDevicesPendingCount] = useState<PalletDevicePendingCount[]>([]);
  const navigate = useNavigate()
  const screens = useBreakpoint();
  const { language } = useLanguage();
  const [corridorFilter, setCorridorFilter] = useState<string>("");
  const [deviceFilter, setDeviceFilter] = useState<string[]>([]);
  const [displayUnexploredsFilter, setDisplayUnexploredsFilter] = useState<boolean>(false);

  const { Panel } = Collapse;

  const onApplyFilters = useCallback(
    (
      device: string[],
      corridor: string,
      displayUnexploredsFilter: boolean
    ) => {
      setDeviceFilter(device)
      setCorridorFilter(corridor);
      setDisplayUnexploredsFilter(displayUnexploredsFilter);
    },
    []
  );
  const filteredSupervisions = useMemo(() => {
    let filtered = supervisions;
    if (deviceFilter.length > 0) {
      filtered = filtered.filter((item) =>
        deviceFilter.includes(item.device_name))
    }
    if (corridorFilter !== "") {
      filtered = filtered.filter((entry) =>
        entry.corridor_name
          .toLocaleUpperCase()
          .includes(corridorFilter.toLocaleUpperCase())

      );
    }
    if (displayUnexploredsFilter) {
      filtered = filtered.filter((entry) => entry.reading_percentage > 0.5);
    }
    return filtered;
  }, [
    supervisions,
    deviceFilter,
    corridorFilter,
    displayUnexploredsFilter
  ]);

  const updateContent = useCallback(() => {
    setLoading(true)
    if (warehouseIdx === undefined) return;
    SupervisionService.getAll(parseInt(warehouseIdx), navigate)
      .then((supervisions) => {
        setsupervisions(supervisions || []);
        setCorridorsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch supervisions:", error);
        setsupervisions([]);
        setCorridorsLoading(false);
      });
    SupervisionService.getSupervisionWarehouse(parseInt(warehouseIdx), navigate)
      .then((supervisionWarehouse) => {
        setSupervisionWarehouse(supervisionWarehouse || undefined);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch warehouses:", error);
        setLoading(false);
      });
    SupervisionService.getSupervisionPendingCount(parseInt(warehouseIdx), navigate)
      .then((pendingCount) => {
        if (pendingCount != null) {
          setDevicesPendingCount(pendingCount || []);
        }
      })
  }, [warehouseIdx, navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 300000);
    return () => clearInterval(interval);
  }, [updateContent]);

  const openNotificationWithIcon = useCallback(() => {
    if (warehouseIdx === undefined) return;
    SupervisionService.getSupervisionAlert(parseInt(warehouseIdx), navigate).then((supervisionAlert) => {
      if (supervisionAlert) {
        if (supervisionAlert.error.length > 0) {
          const errorCorridors = supervisionAlert.error.join(", ");
          notification.error({
            message: <strong>Critical reading effectiveness</strong>,
            description: `Corridors: ${errorCorridors}`,
            style: { backgroundColor: "#fff2f0", border: "1px solid #ffccc7" },
            duration: 20
          })
        }
        if (supervisionAlert.warning.length > 0) {
          const warningCorridors = supervisionAlert.warning.join(", ");
          notification.warning({
            message: <strong>Low reading effectiveness</strong>,
            description: `Corridors: ${warningCorridors}`,
            duration: 20
          })
        }
      }
    })
      .catch((error) => {
        console.error("Failed to fetch warehouses:", error);
      });
  }, [navigate, warehouseIdx])

  useEffect(() => {
    openNotificationWithIcon();
    const intervalId = setInterval(() => {
      openNotificationWithIcon();
    }, 60000);
    return () => clearInterval(intervalId);
  }, [openNotificationWithIcon]);

  const handleResize = (idx: number, entry: ResizeObserverEntry) => {
    setParentSizes((prevSizes) => ({
      ...prevSizes,
      [idx]: {
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      },
    }));
  };

  return (
    <Card>
      <Spin spinning={loading}>
        {supervisionWarehouse && <Card
          title={supervisionWarehouse.warehouse_name}
          extra={<Link to={`/supervision`} >
            <Button
              type="primary"
              shape="round"
              icon={<CaretLeftOutlined />}
            >
              {language === "es" ? "Atrás" : "Back"}
            </Button>
          </Link>}>
          {supervisionWarehouse.is_processing &&
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Row gutter={[10, 10]}>
                  <Col span={24} style={{ textAlign: 'center' }} >
                    <Card bordered={false}>
                      <Statistic
                        title={<h3>{language === "es" ? "Número de Conteo" : "Counting number"} </h3>}
                        value={supervisionWarehouse.counting_number}
                        groupSeparator=""
                      />
                    </Card>
                  </Col>
                  <Col span={24} style={{ textAlign: 'center' }} >
                    <Card bordered={false}>
                      <Statistic
                        title={<h3>{language === "es" ? "Cantidad de \n Dispositivos" : "Devices counting"}</h3>}
                        value={supervisionWarehouse.devices_count}
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col span={screens.xs ? 24 : 8} style={{ textAlign: 'center' }}>
                <Card title={language === "es" ? "Lecturas" : "Readings"}>
                  <Row gutter={[10, 15]}>
                    <Col span={24}>
                      <Progress type="circle" percent={parseFloat((supervisionWarehouse.reading_percentage * 100).toFixed(2))}
                        format={(percent) => `${percent}%`} size={100} success={{ progress: -1 }} />
                    </Col>
                    <Col span={24}>
                      <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{`${supervisionWarehouse.read_positions} / ${supervisionWarehouse.total_count}`}</b>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={screens.xs ? 24 : 8} style={{ textAlign: 'center' }}>
                <Card title={language === "es" ? "Efectividad" : "Effective"}>
                  <Row gutter={[10, 15]}>
                    <Col span={24}>
                      <Progress type="circle" percent={parseFloat((supervisionWarehouse.effective_percentage * 100).toFixed(2))}
                        format={(percent) => `${percent}%`} size={100}
                        strokeColor={supervisionWarehouse.effective_percentage > 0.75 ? "#1677ff" : supervisionWarehouse.effective_percentage > 0.50 ? "#fadb14" : "#f5222d"} />
                    </Col>
                    <Col span={24}>
                      <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{`${supervisionWarehouse.effective_readings} / ${supervisionWarehouse.read_positions}`}</b>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          }
          {screens.lg && <Divider />}
          {devicesPendingCount && screens.lg === true &&
            <Row gutter={[10, 10]}>
              {devicesPendingCount.map((devicePendigCount, i) => (
                <Col key={i} span={8} style={{ textAlign: 'center' }}>
                  <Card title={devicePendigCount.name}
                    extra={<Link to={`logs/${devicePendigCount.idx}`} >
                      <Button
                        type="primary"
                        shape="round"
                        icon={<InfoCircleOutlined />}
                      >
                        {language === "es" ? "Detalles" : "Details"}
                      </Button>
                    </Link>}>
                    <Row gutter={[10, 15]}>
                      <Col span={12}>
                        <Card bordered={false}>
                          <Statistic
                            title={language === "es" ? "Lecturas pendientes en el dispositivo" : "Device pending readings"}
                            value={devicePendigCount.pending_readings_count}
                          />
                        </Card>
                      </Col>
                      <Col span={12}>
                        <Card bordered={false}>
                          <Statistic
                            title={language === "es" ? "Imagenes pendientes en el dispositivo" : "Device pending images"}
                            value={devicePendigCount.pending_images_count}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          }
        </Card>}
        <Divider >{language === "es" ? "Pasillos" : "Corridors"}</Divider>
        <Spin spinning={corridorsLoading && !loading}>
          <CorridorsControls onApply={onApplyFilters} devicesNames={devicesPendingCount.map(entry => entry.name)} />
          <Divider />
          {supervisions &&
            <Row gutter={[16, 16]} style={{ overflow: "auto" }}>
              {filteredSupervisions.map((supervision, i) => (
                <Col key={i} xs={24} sm={24} md={12} lg={8} style={{ overflow: "auto" }}>
                  <Collapse key={supervision.corridor_name}>
                    <Panel header={supervision.corridor_name} extra={<Link to={`${supervisionWarehouse?.sweep_idx}/${supervision.corridor_idx}`} >
                          <Button
                            type="primary"
                            size="small"
                            shape="round"
                            icon={<InfoCircleOutlined />}
                          >
                            {language === "es" ? "Detalles" : "Details"}
                          </Button>
                        </Link>} key={supervision.corridor_name}>
                      {/* <Card
                        ref={(el) => {
                          if (el) {
                            const observer = new ResizeObserver((entries) => {
                              for (let entry of entries) {
                                if (entry.target === el) {
                                  handleResize(i, entry);
                                }
                              }
                            });
                            observer.observe(el);
                          }
                        }}
                        style={{ minHeight: "270px", overflow: "auto" }}
                      > */}
                        {supervision.corridor_idx ? (
                          <CorridorInfo
                            parentWidth={parentSizes[i]?.width || 0}
                            parentHeight={parentSizes[i]?.height || 0}
                            supervision={supervision}
                            loading={loading}
                          />
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                      {/* </Card> */}
                    </Panel>
                  </Collapse>
                </Col>
              ))}
            </Row>}
        </Spin>
      </Spin>
    </Card>)
}

export default Corridor;