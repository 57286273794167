import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Card, Space, Spin, Tabs, TabsProps, Tag } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";

import SweepMap from "./tab3/SweepMap";
import SweepService from "../../../../../services/SweepService";
import {  /*SweepPositionRequest, SweepPositionShow,*/ SweepShow } from "../../../../../models/Sweep";
// import ImagesReviewTab from "./tab2/ImagesReviewTab";
import Positions from "./tab1/Positions";
import BigPicture from "./tab4/BigPicture";
import SettingsService from "../../../../../services/SettingsService";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import UserContext from "../../../../../context/UserContext";
import { ExportOutlined } from "@ant-design/icons";
import { useLanguage } from "../../../../../context/LanguageContext";

const SweepDetails = () => {
  const isMobile = useContext(UserContext).isMobile;
  const { language } = useLanguage();
  const { sweepId } = useParams();
  const screens = useBreakpoint();
  const [showPictures, setShowPictures] = useState<boolean>(false)

  const [sweep, setSweep] = useState(new SweepShow());
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [previous, setPrevious] = useState<string | null>(null);
  // const [next, setNext] = useState<string | null>(null);
  // const [currentPosition, setCurrentPosition] = useState<SweepPositionShow | null>(
  //   null
  // );
  const [repeatedPositions, setRepeatedPositions] = useState<string[]>([]);
  let tabId = searchParams.get("tab") || "1";
  // let positionLabel = searchParams.get("positionLabel") || "0";
  const navigate = useNavigate()
  const updateContent = useCallback(() => {
    if (sweepId) {
      SweepService.getOne(sweepId, navigate).then((sweep) => {
        if (sweep == null) {
          return;
        }
        // var positionLabel_ = positionLabel
        // if (positionLabel_ === "0" && sweep.positions.length > 0) {
        //   positionLabel_ = sweep.positions[0].position_label
        // }

        // // Find the index of the current positionLabel
        // const currentPositionIndex = sweep.positions.findIndex(position => position.position_label === positionLabel_);

        // // Calculate the index of the previous positionLabel
        // const previousPositionIndex = currentPositionIndex > 0 ? currentPositionIndex - 1 : null;
        // const previousPositionLabel = previousPositionIndex !== null ? sweep.positions[previousPositionIndex].position_label : null;

        // // Calculate the index of the next positionLabel
        // const nextPositionIndex = currentPositionIndex < sweep.positions.length - 1 ? currentPositionIndex + 1 : null;
        // const nextPositionLabel = nextPositionIndex !== null ? sweep.positions[nextPositionIndex].position_label : null;

        // // Set the previous and next position labels
        // setPrevious(previousPositionLabel);
        // setNext(nextPositionLabel);
        // const positionrequest=new SweepPositionRequest();
        //   positionrequest.sweep_id = parseInt(sweepId);
        //   positionrequest.pos_label = positionLabel_
        // SweepService.getSweepPosition(positionrequest).then((position)=>{
        //   if (position == null) {
        //     return;
        //   }
        //   setCurrentPosition((position));
        // })

        const positionCounts: Map<string, number> = new Map();

        // Iterate through the positions to count occurrences of label_position
        for (const position of sweep.positions) {
          const labelPosition = position.position_label;
          if (labelPosition) {
            // Increment the count for the current label_position
            const count = positionCounts.get(labelPosition) || 0;
            positionCounts.set(labelPosition, count + 1);
          }
        }

        // Filter out label_positions with count greater than 1 (i.e., repeated labels)
        const repeatedLabels: string[] = [];
        positionCounts.forEach((count, label) => {
          if (count > 1) {
            repeatedLabels.push(label);
          }
        });
        setRepeatedPositions(repeatedLabels)
        setSweep(sweep);
        setLoading(false);
      });
      SettingsService.getSettings().then((settings) => {
        if (settings === null) {
          return
        }
        setShowPictures(settings.show_sweep_positions_pictures)
      })
    }
  }, [sweepId /*, positionLabel*/, navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 6000000);
    return () => clearInterval(interval);
  }, [updateContent]);


  const tabs: TabsProps["items"] = [
    {
      key: "1",
      label:language==="es"?"Resumen":"Summary",
      children: sweep.positions && (
        <Positions
          sweepStatus={sweep.status}
          sweepId={sweep.idx}
          positions={sweep.positions}
          onUpdateContent={updateContent}
          repeatedPositions={repeatedPositions}
          showPictures={showPictures}
        />
      ),
    },
    // {
    //   key: "2",
    //   label: "Images",
    //   children: currentPosition && (
    //     <ImagesReviewTab
    //       sweepStatus = {sweep.status}
    //       position={currentPosition}
    //       sweepId={sweepId ? parseInt(sweepId) : 0}
    //       onUpdateContent={updateContent}
    //       previous={previous}
    //       next={next}
    //       loading={loading}
    //     />
    //   ),
    // },
    ...(!screens.lg
      ? []
      : [
        {
          key: "2",
          label: language==="es"?"Mapa":"Map",
          children:
            sweepId === undefined ? (
              <></>
            ) : (
              sweep.idx&& sweep.warehouse && <SweepMap sweepId={sweep.idx.toString()} warehouseIdx={sweep.warehouse.idx.toString()} sweep={sweep} />
            ),
        },
        {
          key: "3",
          label: language==="es"?"Gigantografía":"Big Picture",
          children:
            sweepId === undefined ? (
              <></>
            ) : (
              sweep.idx && <BigPicture sweepId={sweepId} sweep={sweep} />
            ),
        },
      ]),
  ];
  return (
    <>
      <Spin spinning={loading}>
        <Card title={<Space>{language==="es"?sweep.counting_number ? "Barrido: " + sweep.counting_number + " - " + sweep.warehouse?.name : "":sweep.counting_number ? "Sweep: " + sweep.counting_number + " - " + sweep.warehouse?.name : ""}<Tag color={sweep.status === "processing" ? "blue" : sweep.status === "processed" ? "green" : "red"}>
          <h6 style={{ margin: 0, textAlign: "center", width: "100%" }}>
            {sweep.timestamp_start.split(" ")[0]}
          </h6>
        </Tag></Space>}
          style={{
            margin: isMobile ? '10px' : '20px',
            padding: isMobile ? '10px' : '20px',
          }}
          extra={
            <Link to={`/sweeps/${sweepId}/corridor_states`} >
              <Button
                type="primary"
                shape="round"
                icon={<ExportOutlined />}
              >
                {language==="es"?"Estado de los pasillos":"Corridors Status"}
              </Button>
            </Link>
            // !isMobile&&<Link to={`/sweeps/${sweepId}/revision`} >
            //   <Button
            //     type="primary"
            //     shape="round"
            //     icon={<PlayCircleOutlined />}
            //   >
            //     Video Revision
            //   </Button>
            // </Link>
          }>

          <Tabs
            destroyInactiveTabPane={true}
            items={tabs}
            activeKey={tabId}
            onTabClick={(activeKey) => {
              setSearchParams({ tab: activeKey });
            }}
          />
        </Card>
      </Spin>
    </>
  );
};

export default SweepDetails;
