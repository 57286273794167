import RackTemplate from "./RackTemplate";

export class CorridorSummary {
  id: string = "";
  idx: number = 0;
  name: string = "";
  rack_template: string | undefined = undefined;
  positions: Number = 0;
  empty_positions: Number = 0;
  full_positions: Number = 0;
  unknown_positions: Number = 0;
}
export class CorridorBase {
  name: string = "";
  rack_template_idx: number = 0;
  warehouse_idx: number = 0;
}
export class CorridorShow {
  id: string = "";
  name: string = "";
  rack_template: RackTemplate | undefined = undefined;
}
export default class Corridor {
  id: string = "";
  idx: number = 0;
  name: string = "";
  rack_template: RackTemplate | undefined = undefined;

  public static from(json: any) {
    return Object.assign(new Corridor(), json) as Corridor;
  }
}
