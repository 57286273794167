import React from "react";
import { Tag, Divider } from "antd";
import { useLanguage } from "../../../../../../context/LanguageContext";

const CorridorColorReferences = () => {
  const { language } = useLanguage();
  return (
    <div style={{ textAlign: "center" }}>
      <Divider orientation="center">{language==="es"?"Referencias de Color":"Color References"}</Divider>
      <Tag color="#4CAF50">{language==="es"?"Ocupada":"Full"}</Tag>
      <Tag color="#a0d911">{language==="es"?"Vacia":"Empty"}</Tag>
      <Tag color="#FF5252">{language==="es"?"Sin Explorar":"Unexplored"}</Tag>
      <Tag color="#FFC107">{language==="es"?"Invalida":"Invalid"}</Tag>
      <Tag color="#bfbfbf" style={{ color: "#333333" }} >{language==="es"?"Ignorada":"Ignored"}</Tag>
    </div>
  );
};

export default CorridorColorReferences;