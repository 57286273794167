import React from "react";
import { Button, Popover, Row } from "antd";
import { LayoutShowSchema } from "../../../../../models/Warehouse";
import { useLanguage } from "../../../../../context/LanguageContext";

interface GridProps {
  rackTemplate: LayoutShowSchema;
  containerHeight?: number;
}

const RackTemplateMap: React.FC<GridProps> = ({
  rackTemplate,
  containerHeight
}) => {
  const { language } = useLanguage();
  const isCellIgnored = (row: number, col: number): boolean => {
    return rackTemplate.ignore_cells.some(cell => cell[0] === col && cell[1].includes(row));
  };
  const renderGrid = () => {
    const grid = [];
    console.log(rackTemplate)
    for (let i = rackTemplate.level_range[1]; i >= rackTemplate.level_range[0]; i--) {
      const row = [];
      const start = rackTemplate.orientation === "right"?rackTemplate.column_range[1]:rackTemplate.column_range[0]
      const end = rackTemplate.orientation === "right"?rackTemplate.column_range[0]:rackTemplate.column_range[1]
      const inc = rackTemplate.orientation === "right"?-1:1
      const compare = (colIter:number,end:number):boolean => {
        return rackTemplate.orientation === "right"?colIter>=end:colIter<=end
      }
      for (let j = start; compare(j,end); j+=inc) {
        const colText = String(j).padStart(2, "0");
        const levelText = String(i);
        const positionText = colText + levelText;

        const buttonStyle = {
          margin: "2px",
          backgroundColor: isCellIgnored(i,j) ? "#FF5252" : "#4CAF50",
        };

        row.push(
          <Popover title={language==="es"? `Posición: ${positionText}`:`Position: ${positionText}`} key={`tooltip-${i}-${j}`}>
            <Button
              ghost
              key={`button-${i}-${j}`}
              type={"primary"}
              size="small"
              style={buttonStyle}
            ></Button>
          </Popover>
        );
      }
      grid.push(
        <div
          key={`row-${i}`}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {row}
        </div>
      );
    }

    return grid;
  };

  return (
    <Row
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: containerHeight ? containerHeight-100 : "auto",
        width: "100%" ,
        overflow: "scroll"
      }}
    >
      {renderGrid()}
    </Row>
  );
};

export default RackTemplateMap;
