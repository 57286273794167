import React from "react";
import { Tag, Divider } from "antd";
import { useLanguage } from "../../../../../context/LanguageContext";

const LayoutColorReferences = () => {
  const { language } = useLanguage();
  return (
    <div style={{ textAlign: "center" }}>
      <Divider orientation="center">{language === "es" ? "Referencia de colores":"Color References"}</Divider>
      <Tag color="#4CAF50">{language === "es" ? "Habilitada":"Enabled"}</Tag>
      <Tag color="#FF5252">{language === "es" ? "Ignorada":"Ignored"}</Tag>
    </div>
  );
};

export default LayoutColorReferences;